import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'ui';
import Spinner from './Spinner';

export const Wrapper = styled.button`
  display: flex;
  border-radius: 28px;
  padding: ${({ theme }) => theme.spacing(0.625)}
    ${({ theme }) => theme.spacing(1.5)};
  background: ${({ theme }) => theme.primaryGradient};
  box-shadow: ${({ theme }) => theme.buttonShadow};
  color: #fff;
  border: 0;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  min-width: 10em;
  cursor: pointer;
  transition: color 150ms ease, opacity 150ms ease, background 150ms ease,
    box-shadow 150ms ease;
  text-decoration: none;
  outline: none;
  font-size: 1rem;

  ${media.tablet`
    font-size: 1.125rem;
  `}

  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    font-size: 24px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryLight};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textDisabled};
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.disabled};
      color: ${({ theme }) => theme.textDisabled};
    `}

  ${({ variant, theme }) =>
    variant === 'onDarkBackground' &&
    `
      background: #fff;
      color: ${theme.primary};

      &:hover {
        background: rgba(255, 255, 255, 0.87);
      }
    `}

  ${({ variant, theme }) =>
    variant === 'onSuccessBackground' &&
    `
      background: #fff;
      color: ${theme.success};
      box-shadow: none;
      font-weight: 600;

      &:hover {
        background: rgba(255, 255, 255, 0.87);
      }
    `}

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background: ${theme.secondaryGradient};
      box-shadow: ${theme.buttonShadowSecondary};
      color: #fff;

      &:hover {
        background: ${theme.secondaryLight};
      }
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background: transparent;
      color: ${({ theme }) => theme.primary};
      border: 1px solid ${({ theme }) => theme.primary};
      box-shadow: none;

      &:hover {
        border: 1px solid ${({ theme }) => theme.primaryLight};
        color: ${({ theme }) => theme.primaryLight};
      }

      &:disabled {
        border: 1px solid ${({ theme }) => theme.separator};
        color: ${({ theme }) => theme.textDisabled};
        &:hover {
          background-color: transparent;
        }
      }
    `}

  ${({ variant }) =>
    variant === 'outlineInverted' &&
    css`
      background: transparent;
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        color: rgba(255, 255, 255, 0.87);
        border: 1px solid rgba(255, 255, 255, 0.87);
      }
    `}

  ${({ variant, theme }) =>
    variant === 'destroy' &&
    `
      background-color: ${theme.error};
      color: #fff;
    `}

    ${({ size }) =>
    size === 'full' &&
    `
        width: 100%
      `}

  ${({ size }) =>
    size === 'small' &&
    css`
      min-width: 0;
      padding: ${({ theme }) => theme.spacing(0.5)}
        ${({ theme }) => theme.spacing()};

      ${media.tablet`
        min-width: 10em;
        padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) =>
        theme.spacing(1.5)};
      `}
    `}
`;

export const Button = ({
  icon,
  isLoading,
  disabled,
  children,
  to,
  ...props
}) => (
  <Wrapper
    disabled={disabled || isLoading}
    to={to}
    as={Boolean(to) && Link}
    {...props}
  >
    {isLoading && <Spinner color="currentColor" />}
    {!isLoading && icon}
    {children}
  </Wrapper>
);

export default Button;
