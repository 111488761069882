import React from 'react';
import styled from 'styled-components';
import { Container, Buttons, media } from 'ui';
import { Button } from '.';
import { get } from 'lodash';
import HeroShape from 'components/HeroShape';
import Laptop from 'components/Laptop';

export const MAX_HERO_BOTTOM_DIMENSION = 300;

const HeroWrapper = styled.div`
  position: relative;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 25vw;
  margin-bottom: 25vw;

  ${media.desktop`
    padding-bottom: ${MAX_HERO_BOTTOM_DIMENSION / 2}px;
    margin-bottom: ${MAX_HERO_BOTTOM_DIMENSION / 2}px;
  `}
`;

const HeroTitle = styled.h2`
  font-size: 2rem;
  font-size: min(calc(1.5rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 2rem);
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  font-size: min(calc(1rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 1.25rem);
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.textLight};
  line-height: 32px;
`;

const HeroBis = ({ data, locale }) => (
  <HeroWrapper>
    <HeroShape />

    <HeroContent>
      <Container size="narrow">
        <HeroTitle>{get(data, 'datoCmsHomepage.title')}</HeroTitle>
        <HeroSubtitle>{get(data, 'datoCmsHomepage.subtitle')}</HeroSubtitle>
        <Buttons>
          <Button to={get(data, 'datoCmsHomepage.ctaLink')}>
            {get(data, 'datoCmsHomepage.ctaTitle')}
          </Button>
        </Buttons>
      </Container>

      <Laptop
        source={get(data, 'datoCmsHomepage.mainPicture.url')}
        mobileSource={get(data, 'datoCmsHomepage.mobilePicture.url')}
      />
    </HeroContent>
  </HeroWrapper>
);

export default HeroBis;
