import React from 'react';
import styled from 'styled-components';
import { media } from 'ui';

const Wrapper = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  height: 10vmin;
  width: 100%;
  bottom: 0;

  ${media.tablet`
    height: 15vmin;
  `}

  ${media.desktop`
    height: 200px;
  `}
`;

export default props => (
  <Wrapper viewBox="0 0 1441 218" preserveAspectRatio="none" {...props}>
    <path
      d="M0 0c480.667 289.333 961 289.333 1441 0v218H0V0z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </Wrapper>
);
