import React from 'react';
import { Media, MediaImage, MediaBody, media } from 'ui';
import styled from 'styled-components';

export const FeatureIcon = styled.img`
  max-width: 48px;

  ${media.tablet`
    max-width: 64px;
  `}
`;

export const FeatureName = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const FeatureDescription = styled.p`
  color: ${({ theme }) => theme.textLight};
`;

const Feature = ({ feature }) => (
  <Media size="big">
    <MediaImage>
      <FeatureIcon src={feature.icon.url}></FeatureIcon>
    </MediaImage>
    <MediaBody>
      <FeatureName>{feature.name}</FeatureName>
      <FeatureDescription>{feature.description}</FeatureDescription>
    </MediaBody>
  </Media>
);

export default Feature;
