import styled, { css } from 'styled-components';

export const FormField = styled.div``;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;

  ${({ hasError, theme }) =>
    hasError &&
    `
      color: ${theme.error};
    `}
`;

export const Input = styled.input`
  display: inline-block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
  color: inherit;
  outline: none;
  font-size: 1rem;
  height: 40px;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.separatorDark};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.backgroundDisabled};
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const ErrorMessageWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.error};
  font-size: 0.825rem;
`;
