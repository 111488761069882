import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing()};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const BenefitIcon = styled.img`
  max-width: 90px;
`;

const BenefitName = styled.p`
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const BenefitDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.textLight};
`;

const Benefit = ({ benefit }) => (
  <Card>
    <BenefitIcon src={benefit.icon.url} alt=""></BenefitIcon>
    <BenefitName>{benefit.name}</BenefitName>
    <BenefitDescription>{benefit.description}</BenefitDescription>
  </Card>
);

export default Benefit;
