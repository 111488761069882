import React from 'react';
import {
  SEO,
  Button,
  Feature,
  Hero,
  HeroBis,
  Benefit,
  Carousel,
  SectionShapes,
} from 'components';
import {
  Container,
  Section,
  SectionGrid,
  SectionTitle,
  SectionSubtitle,
  SectionContent,
  SectionImage,
  SectionShape,
  FeaturesList,
  BenefitsList,
  Buttons,
  AwardsList,
  Award,
  TestimonialsSection,
  TestimonialsList,
  CallToActionWrapper,
  CallToActionTitle,
} from 'ui';
import { get } from 'lodash';
import TestimonialCard from 'components/TestimonialCard';
import shapesTestimonial from 'images/shapes-testimonial.svg';

const Homepage = ({ data, locale }) => {
  return (
    <>
      <SEO
        title={get(data, 'datoCmsHomepage.seo.title')}
        description={get(data, 'datoCmsHomepage.seo.description')}
        image={get(data, 'datoCmsHomepage.seo.image.url')}
        twitterCard={get(data, 'datoCmsHomepage.seo.twitterCard')}
        datoCmsSite={data.datoCmsSite}
        lang={locale}
      />

      <Hero data={data} locale={locale} />
      <HeroBis data={data} locale={locale} />

      <Section>
        <Container>
          <SectionTitle alignment="center">
            {get(data, 'datoCmsHomepage.benefitsTitle')}
          </SectionTitle>

          <SectionSubtitle alignment="center">
            {get(data, 'datoCmsHomepage.benefitsSubtitle')}
          </SectionSubtitle>

          <BenefitsList>
            {get(data, 'datoCmsHomepage.benefits', []).map(
              ({ id, ...benefit }) => (
                <Benefit key={id} benefit={benefit}></Benefit>
              ),
            )}
          </BenefitsList>
        </Container>
      </Section>

      {get(data, 'datoCmsHomepage.sections', []).map(
        ({ title, contentNode, layout, image }, index) => (
          <Section key={index}>
            <Container hasBarePadding>
              <SectionGrid layout={layout[0]}>
                <SectionContent>
                  <SectionTitle>{title}</SectionTitle>
                  <SectionSubtitle
                    dangerouslySetInnerHTML={{
                      __html: contentNode.childMarkdownRemark.html,
                    }}
                  />
                  <Buttons
                    alignment={
                      layout[0] !== 'center' && layout[0] !== 'centerWithShape'
                        ? 'left'
                        : null
                    }
                  >
                    <Button to={data.datoCmsHomepage.ctaLink}>
                      {data.datoCmsHomepage.ctaTitle}
                    </Button>
                  </Buttons>
                </SectionContent>
                <SectionImage layout={layout[0]}>
                  {Boolean(layout[0]) && <SectionShapes layout={layout[0]} />}

                  <img src={image.url} alt="" />
                </SectionImage>
              </SectionGrid>
            </Container>
          </Section>
        ),
      )}

      <Section>
        <Container>
          <SectionTitle alignment="center">
            {get(data, 'datoCmsHomepage.featureTitle')}
          </SectionTitle>
          <SectionSubtitle alignment="center" s>
            {get(data, 'datoCmsHomepage.featureSubtitle')}
          </SectionSubtitle>

          <FeaturesList>
            {get(data, 'datoCmsHomepage.features', []).map(
              ({ id, ...feature }) => (
                <Feature key={id} feature={feature}></Feature>
              ),
            )}
          </FeaturesList>
          <Buttons>
            <Button to={data.datoCmsHomepage.ctaLink}>
              {data.datoCmsHomepage.ctaTitle}
            </Button>
          </Buttons>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle alignment="center" variant="primary">
            {get(data, 'datoCmsHomepage.partnerTitle')}
          </SectionTitle>
        </Container>
        <Carousel data={get(data, 'allDatoCmsPartner.edges')} />
        <Container>
          <SectionTitle alignment="center" variant="primary">
            {get(data, 'datoCmsHomepage.certificationsTitle')}
          </SectionTitle>
          <AwardsList>
            {get(data, 'allDatoCmsAward.edges').map(({ node }) => (
              <Award src={node.logo.url} key={node.id} alt={node.name} />
            ))}
          </AwardsList>
        </Container>
      </Section>

      <TestimonialsSection variant="transparent">
        <SectionShape src={shapesTestimonial} alt="" />

        <Container>
          <SectionTitle alignment="center">
            {get(data, 'datoCmsHomepage.testimonialTitle')}
          </SectionTitle>
          <SectionSubtitle alignment="center">
            {get(data, 'datoCmsHomepage.testimonialSubtitle')}
          </SectionSubtitle>

          <TestimonialsList>
            {get(data, 'datoCmsHomepage.testimonials', []).map(
              (testimonial, index) => (
                <TestimonialCard testimonial={testimonial} key={index} />
              ),
            )}
          </TestimonialsList>
        </Container>
      </TestimonialsSection>

      <Section variant="transparent">
        <Container>
          <CallToActionWrapper>
            <CallToActionTitle>
              {get(data, 'datoCmsHomepage.callToActionTitle')}
            </CallToActionTitle>
            <div>
              <Button
                variant="onSuccessBackground"
                to={locale === 'en' ? '/en/demo' : '/demo'}
              >
                {get(data, 'datoCmsHomepage.callToActionButton')}
              </Button>
            </div>
          </CallToActionWrapper>
        </Container>
      </Section>
    </>
  );
};

export default Homepage;
