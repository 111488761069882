import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { theme, media } from 'ui';
import { get } from 'lodash';
import { Header, Footer } from '.';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import '@fontsource/montserrat';
import '@fontsource/nunito';
import '@fontsource/nunito/800.css';
import checkCircle from 'images/check-circle.svg';
import CookieConsent from 'react-cookie-consent';
import { slide as Menu } from 'react-burger-menu';
import {
  getBusinessMenuItems,
  getCustomerMenuItems,
  getFeaturesMenuItems,
} from 'utils';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
    color: ${({ theme }) => theme.text};
    line-height: 1.5;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style-type: none;
    background: url(${checkCircle}) no-repeat 0 0.62em;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-top: 0.5em;

    + li {
      margin-top: ${({ theme }) => theme.spacing()}
    }
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background};

  ${({ variant }) =>
    variant === 'home' &&
    `
      background-color: #fff
    `}
`;

const Main = styled.main`
  flex: 1;
`;

const BurgerMenu = styled.div`
  ${media.tablet`
    display: none;
  `}

  .bm-burger-button {
    position: fixed;
    width: 28px !important;
    height: 22px !important;
    left: 16px !important;
    top: 16px !important;
  }

  .bm-burger-bars {
    background: #ffffff;
    height: 15% !important;
  }

  .bm-burger-bars-hover {
    background: #ffffff;
    opacity: 0.8;
  }

  .bm-cross-button {
    height: 30px;
    width: 30px;
    top: 16px !important;
    right: 24px !important;
  }

  .bm-cross {
    background: ${({ theme }) => theme.primary};
    height: 24px !important;
    width: 2px !important;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    min-width: 300px !important;
    width: 100% !important;
  }

  .bm-menu {
    background: #ffffff;
    padding: 2.5em 0.5em 0;
    font-size: 1em;
  }

  .bm-item-list {
    color: ${({ theme }) => theme.text};
    padding: 0.8em;
  }

  .bm-item,
  a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    padding: 0.6em 0;
    font-weight: 300 !important;

    &:active,
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const BurgerMenuSection = styled.div`
  padding-top: 0 !important;
  margin-top: 0.75em;
`;

const BurgerMenuSectionTitle = styled.div`
  font-size: 1.15em;
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 0.5em;
`;

const Layout = ({ variant, children, data, locale }) => {
  const layoutData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Page variant={variant}>
          <Header
            siteTitle={layoutData.site.siteMetadata.title}
            variant={variant}
            locale={locale}
            paprworkLink={get(data, 'datoCmsHomepage.paprworkLink')}
          />

          <BurgerMenu>
            <Menu>
              <Link key="home" href="/">
                {locale === 'en' ? 'Home' : 'Accueil'}
              </Link>
              <Link key="home" href={locale === 'en' ? '/en/demo' : '/demo'}>
                {locale === 'en' ? 'Contact' : 'Contact'}
              </Link>
              <Link key="home" href={get(data, 'datoCmsHomepage.paprworkLink')}>
                {locale === 'en' ? 'Signin' : 'Connexion'}
              </Link>

              <BurgerMenuSection>
                <BurgerMenuSectionTitle>
                  {locale === 'en' ? 'Clients' : 'Cas clients'}
                </BurgerMenuSectionTitle>
                {getCustomerMenuItems(locale).length > 0 &&
                  getCustomerMenuItems(locale).map((item, index) => (
                    <Link key={item.key} href={item.href}>
                      {item.title}
                    </Link>
                  ))}
              </BurgerMenuSection>

              <BurgerMenuSection>
                <BurgerMenuSectionTitle>
                  {locale === 'en' ? 'Features' : 'Fonctionnalités'}
                </BurgerMenuSectionTitle>
                {getFeaturesMenuItems(locale).length > 0 &&
                  getFeaturesMenuItems(locale).map((item, index) => (
                    <Link key={item.key} href={item.href}>
                      {item.title}
                    </Link>
                  ))}
              </BurgerMenuSection>

              <BurgerMenuSection>
                <BurgerMenuSectionTitle>
                  {locale === 'en' ? 'Business cases' : 'Métiers'}
                </BurgerMenuSectionTitle>
                {getBusinessMenuItems(locale).length > 0 &&
                  getBusinessMenuItems(locale).map((item, index) => (
                    <Link key={item.key} href={item.href}>
                      {item.title}
                    </Link>
                  ))}
              </BurgerMenuSection>
            </Menu>
          </BurgerMenu>
          <Main>{children}</Main>
          <Footer datoCmsFooter={get(data, 'datoCmsFooter')} locale={locale} />
          <CookieConsent
            location="bottom"
            buttonText={
              locale === 'en' ? 'ACCEPT AND CONTINUE' : 'ACCEPTER ET CONTINUER'
            }
            cookieName="Paprwork Cookie"
            style={{
              background: '#faf9f7',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '14px',
            }}
            buttonStyle={{
              background: '#ff5b4a',
              borderRadius: '4px',
              color: '#fff',
              fontSize: '14px',
            }}
            expires={150}
          >
            {locale === 'en'
              ? `By continuing your navigation on this site, you accept that cookies may be placed on your terminal. These cookies are intended to improve the performance of this site.
              To obtain more information on cookies and to oppose them, you can consult our `
              : `En poursuivant votre navigation sur ce site, vous acceptez que des cookies soient placés sur votre terminal. Ces cookies sont destinés à améliorer la performance de ce site. 
              Pour obtenir plus d’information sur les cookies et vous y opposer, vous pouvez consulter notre `}
            <span>
              <a
                href={get(data, 'datoCmsFooter.dataProtectionPolicy')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {locale === 'en' ? 'Cookie Policy' : 'Politique de cookies'}
              </a>
            </span>
          </CookieConsent>
        </Page>
      </>
    </ThemeProvider>
  );
};
export default Layout;
