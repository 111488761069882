const PAGES_URL = 'https://page.paprwork.io';

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_TAKEN: 'Cette adresse mail est déjà utilisée',
  ERROR: 'Une erreur est survenue, merci de réessayer ultérieurement',
};

export const getLabelFromLocale = (label, locale) => {
  switch (`${label}-${locale}`) {
    case 'firstname-fr':
      return 'Prénom';
    case 'lastname-fr':
      return 'Nom';
    case 'email-fr':
      return 'Email';
    case 'phoneNumber-fr':
      return 'Téléphone';
    case 'company-fr':
      return 'Entreprise';
    case 'firstname-en':
      return 'Firstname';
    case 'lastname-en':
      return 'Lastname';
    case 'email-en':
      return 'Email';
    case 'phoneNumber-en':
      return 'Phone Number';
    case 'company-en':
      return 'Company';
    default:
      return '';
  }
};

export const getPlaceholderFromLocale = (label, locale) => {
  switch (`${label}-${locale}`) {
    case 'firstname-fr':
      return 'Votre prénom…';
    case 'lastname-fr':
      return 'Votre Nom…';
    case 'email-fr':
      return 'Votre adresse email…';
    case 'phoneNumber-fr':
      return 'Votre téléphone…';
    case 'company-fr':
      return 'Votre entreprise…';
    case 'firstname-en':
      return 'Your firstname…';
    case 'lastname-en':
      return 'Your lastname…';
    case 'email-en':
      return 'Your email…';
    case 'phoneNumber-en':
      return 'Your phone number…';
    case 'company-en':
      return 'Your company name…';
    default:
      return '';
  }
};

export const getFeaturesMenuItems = locale => {
  return [
    {
      key: 'simplified-collect',
      title:
        locale === 'en'
          ? 'Simplified document collection'
          : 'Collecte de documents simplifiée',
      href: `${PAGES_URL}/collecte-de-documents`,
    },
    {
      key: 'document-request-model',
      title:
        locale === 'en'
          ? 'Document request models'
          : 'Modèles de demandes de documents',
      href: `${PAGES_URL}/modele-de-document`,
    },
    {
      key: 'automatic-notifications',
      title:
        locale === 'en'
          ? 'Automatic notifications and reminders'
          : 'Notifications et relances automatiques',
      href: `${PAGES_URL}/notifications-et-relances-automatiques`,
    },
    {
      key: 'electronic-signature',
      title:
        locale === 'en' ? 'Electronic signature' : 'Signature électronique',
      href: `${PAGES_URL}/signature-document`,
    },
  ];
};

export const getBusinessMenuItems = locale => {
  return [
    {
      key: 'real-estate',
      title: locale === 'en' ? 'Real estate' : 'Immobilier',
      href: `${PAGES_URL}/immobilier`,
    },
    {
      key: 'legal',
      title: locale === 'en' ? 'Legal' : 'Juridique',
      href: `${PAGES_URL}/juridique`,
    },
    {
      key: 'bailiff',
      title: locale === 'en' ? 'Bailiff' : 'Huissiers de justice',
      href: `${PAGES_URL}/huissiers-de-justice`,
    },
    {
      key: 'education',
      title: locale === 'en' ? 'Education' : 'Éducation',
      href: `${PAGES_URL}/education`,
    },
    {
      key: 'pme',
      title: locale === 'en' ? 'TPE-PME' : 'TPE-PME',
      href: `${PAGES_URL}/tpe-pme`,
    },
    {
      key: 'diagnostics',
      title: locale === 'en' ? 'Diagnosticians' : 'Diagnostiqueurs immobiliers',
      href: `${PAGES_URL}/diagnostiqueurs-immobiliers`,
    },
    {
      key: 'public-figure',
      title: locale === 'en' ? 'Public persons' : 'Personnes publiques',
      href: `${PAGES_URL}/personnes-publiques`,
    },
  ];
};

export const getCustomerMenuItems = locale => {
  return [
    {
      key: 'verified-reviews',
      title: locale === 'en' ? 'Verified customer reviews' : 'Avis vérifiés',
      href: `${PAGES_URL}/avis-verifies`,
    },
  ];
};
