import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  display: grid;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  grid-template-columns: 64px 1fr;
  grid-gap: ${({ theme }) => theme.spacing()};
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 99em;
`;

const CardHeader = styled.div``;

const CardTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 24px;
  margin: 0;
`;

const CardSubtitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLight};
`;

const CardContent = styled.div`
  grid-column: 1 / -1;
  font-size: 20px;
  color: ${({ theme }) => theme.textLight};

  &:before {
    content: '« ';
  }

  &:after {
    content: ' »';
  }
`;

const TestimonialCard = ({ testimonial }) => (
  <Wrapper>
    <Avatar src={get(testimonial, 'avatar.url')} />

    <CardHeader>
      <CardTitle>{testimonial.name}</CardTitle>
      <CardSubtitle>{testimonial.company}</CardSubtitle>
    </CardHeader>

    <CardContent>{testimonial.content}</CardContent>
  </Wrapper>
);

export default TestimonialCard;
