import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
`;

const Content = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  margin-left: 96px;
  height: auto;
  max-height: 160px;
  max-width: 230px;
`;

const Carousel = ({ data }) => {
  const contentRef = useRef();
  const [carouselWidth, setCarouselWidth] = useState(0);

  useEffect(() => {
    setCarouselWidth(contentRef.current.offsetWidth);
  }, []);

  return (
    <Wrapper>
      <Content
        initial={{ translateX: 0 }}
        animate={{ translateX: `${-1 * carouselWidth}px` }}
        transition={{
          ease: 'linear',
          duration: carouselWidth / 20,
          loop: Infinity,
        }}
        ref={contentRef}
      >
        {data.map(({ node }) => (
          <Logo src={node.logo.url} key={node.id} alt={node.name} />
        ))}
      </Content>

      <Content
        initial={{ translateX: 0 }}
        animate={{ translateX: `${-1 * carouselWidth}px` }}
        transition={{
          ease: 'linear',
          duration: carouselWidth / 20,
          loop: Infinity,
        }}
      >
        {data.map(({ node }) => (
          <Logo src={node.logo.url} key={node.id} alt={node.name} />
        ))}
      </Content>
    </Wrapper>
  );
};

export default Carousel;
