import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container, media } from 'ui';
import logoWhite from 'images/logo-white.svg';
import icFacebook from 'images/ic-facebook.svg';
import icTwitter from 'images/ic-twitter.svg';
import icInstagram from 'images/ic-instagram.svg';
import icLinkedin from 'images/ic-linkedin.svg';
import { get } from 'lodash';
import { FaGlobeEurope } from 'react-icons/fa';

const Wrapper = styled.footer`
  background: ${({ theme }) => theme.primaryGradient};
  padding: ${({ theme }) => theme.spacing(2.5)} 0;
  color: #fff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    grid-gap: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: auto 1fr auto;
  `}
`;

const SocialsList = styled.div`
  display: flex;
  justify-content: flex-start;

  ${media.tablet`
    justify-content: flex-end;
  `}

  > * + * {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

const SocialLink = styled.a`
  cursor: pointer;
`;

const NavList = styled.div`
  display: flex;
  font-size: 0.825rem;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  > a {
    display: block;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

  ${media.desktop`
    flex-direction: row;
    align-items: center;

    > a + a {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  `}
`;

const Logo = styled(Link)`
  align-self: center;

  img {
    width: 100%;
    max-width: 130px;
  }
`;

const LocaleLink = styled(Link)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing()};
  color: #fff;
  text-decoration: none;

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }

  ${media.tablet`
    text-align: right;
  `}
`;

const Footer = ({ datoCmsFooter, locale }) => (
  <Wrapper>
    <Container paddingVariant="bareVertical">
      <Grid>
        <Logo to="/">
          <img src={logoWhite} alt="Paprwork" />
        </Logo>
        <NavList>
          <a
            href={get(datoCmsFooter, 'termsOfService')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {get(datoCmsFooter, 'termsOfServiceTitle')}
          </a>
          <a
            href={get(datoCmsFooter, 'dataProtectionPolicy')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {get(datoCmsFooter, 'dataProtectionPolicyTitle')}
          </a>
          <a
            href={get(datoCmsFooter, 'terms')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {get(datoCmsFooter, 'termsTitle')}
          </a>
        </NavList>
        <div>
          <SocialsList>
            {get(datoCmsFooter, 'twitterLink') && (
              <SocialLink
                href={datoCmsFooter.twitterLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={icTwitter} alt="twitter" />
              </SocialLink>
            )}
            {get(datoCmsFooter, 'linkedinLink') && (
              <SocialLink
                href={datoCmsFooter.linkedinLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={icLinkedin} alt="twitter" />
              </SocialLink>
            )}
            {get(datoCmsFooter, 'instagramLink') && (
              <SocialLink
                href={datoCmsFooter.instagramLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={icInstagram} alt="instagram" />
              </SocialLink>
            )}
            {get(datoCmsFooter, 'facebookLink') && (
              <SocialLink
                href={datoCmsFooter.facebookLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={icFacebook} alt="facebook" />
              </SocialLink>
            )}
          </SocialsList>
          <LocaleLink to={locale === 'en' ? `/` : `/en/`}>
            <FaGlobeEurope />
            {locale === 'en' ? 'Français' : 'English'}
          </LocaleLink>
        </div>
      </Grid>
    </Container>
  </Wrapper>
);

export default Footer;
