import React from 'react';
import styled from 'styled-components';
import { Container as UIContainer, media } from 'ui';
import { Link } from 'gatsby';
import { Button } from './';
import logo from 'images/logo.svg';
import logoWhite from 'images/logo-white.svg';
import {
  getBusinessMenuItems,
  getCustomerMenuItems,
  getFeaturesMenuItems,
} from 'utils';

const HeaderWrapper = styled.header`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 10;

  ${({ variant }) =>
    variant === 'home' &&
    `
      background-color: transparent;
      box-shadow: none;
      position: absolute;
      top: 0;
      left: 0;
    `}
`;

const Container = styled(UIContainer)`
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `};
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    justify-content: space-between;
  `}
`;

const Nav = styled.ul`
  display: none;
  height: 80px;
  margin: 0;

  ${media.tablet`
    display: flex;
    align-items: center;
  `}

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};

    ${media.tabletMini`
      margin-left: ${({ theme }) => theme.spacing()};
    `}

    ${media.tablet`
      margin-left: ${({ theme }) => theme.spacing(2)};
    `}
  }

  > li {
    background: none;
  }

  ${({ variant }) =>
    variant === 'home' &&
    `
      ${NavItemLink} {
        color: #fff
      }
  `}
`;

const NavItem = styled.li`
  margin-top: 0;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  z-index: 2;

  :hover {
    cursor: pointer;
  }

  :hover ul,
  ul:hover {
    left: auto;
    opacity: 1;
    transform: translateY(0);
  }
`;

const NavItemLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  font-size: 1.125rem;
`;

const NavItemDropdown = styled.ul`
  left: -99999px;
  opacity: 0;
  position: absolute;
  transform: translateY(-0.5rem);
  z-index: 1;
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0 0;
  background-color: #ffffff;
  padding: 4px 0;
  min-width: 160px;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;

  > li {
    margin: 0;
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing(1)};
    background: none;

    :hover {
      & > a {
        color: ${({ theme }) => theme.primary};
      }
    }

    > a {
      position: relative;
      display: block;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 300;
      color: ${({ theme }) => theme.text};
      text-decoration: none;
      transition: color 150ms ease;
    }
  }
`;

const Logo = styled(Link)`
  img {
    width: 100%;
    max-width: 110px;

    ${media.tablet`
      max-width: 130px;
    `}
  }
`;

const NavItemDropdownItem = ({ item: { key, title, href } }) => (
  <li key={key}>
    <a href={href}>{title}</a>
  </li>
);

const Header = ({ variant, locale, paprworkLink }) => {
  return (
    <HeaderWrapper variant={variant}>
      <Container paddingVariant="bareVertical">
        <NavContainer>
          <Logo to="/">
            <img src={variant === 'home' ? logoWhite : logo} alt="Paprwork" />
          </Logo>

          <Nav variant={variant}>
            <NavItem>
              <NavItemLink>
                {locale === 'en' ? 'Features' : 'Fonctionnalités'}
              </NavItemLink>
              {getFeaturesMenuItems(locale).length > 0 && (
                <NavItemDropdown aria-label="submenu">
                  {getFeaturesMenuItems(locale).map((item, index) => (
                    <NavItemDropdownItem item={item} />
                  ))}
                </NavItemDropdown>
              )}
            </NavItem>
            <NavItem>
              <NavItemLink>
                {locale === 'en' ? 'Business cases' : 'Métiers'}
              </NavItemLink>
              {getBusinessMenuItems(locale).length > 0 && (
                <NavItemDropdown aria-label="submenu">
                  {getBusinessMenuItems(locale).map((item, index) => (
                    <NavItemDropdownItem item={item} />
                  ))}
                </NavItemDropdown>
              )}
            </NavItem>
            <NavItem>
              <NavItemLink>
                {locale === 'en' ? 'Clients' : 'Cas clients'}
              </NavItemLink>
              {getCustomerMenuItems(locale).length > 0 && (
                <NavItemDropdown aria-label="submenu">
                  {getCustomerMenuItems(locale).map((item, index) => (
                    <NavItemDropdownItem item={item} />
                  ))}
                </NavItemDropdown>
              )}
            </NavItem>
            <NavItem>
              <NavItemLink to={locale === 'en' ? '/en/demo' : '/demo'}>
                {locale === 'en' ? 'Contact' : 'Contact'}
              </NavItemLink>
            </NavItem>
            {/* <NavItem to={locale === 'en' ? '/en/' : '/'}>API</NavItem> */}
            <Button
              variant={variant === 'home' ? 'outlineInverted' : 'outline'}
              size="small"
              as="a"
              href={paprworkLink}
            >
              {locale === 'en' ? 'Signin' : 'Connexion'}
            </Button>
          </Nav>
        </NavContainer>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
