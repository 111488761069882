import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import laptop from 'images/laptop.svg';
import { Container, media } from 'ui';
import Smartphone from 'components/Smartphone';

export const MAX_HERO_BOTTOM_DIMENSION = 300;

const Outer = styled(Container)`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(50vw);
  margin-top: -50vw;

  ${media.desktop`
    transform: translateY(${MAX_HERO_BOTTOM_DIMENSION}px);
    margin-top: -${MAX_HERO_BOTTOM_DIMENSION}px;
  `}
`;

const Wrapper = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
  }
`;

const Screen = styled.div`
  position: absolute;
  left: 9.25%;
  top: 5.6%;
  right: 9.25%;
  height: 82%;
  overflow: hidden;
  border-radius: 4px;
`;

const ScreenContent = styled.img`
  transition: transform 30s ease;
`;

const Laptop = ({ source, mobileSource }) => {
  const imageRef = useRef();
  const screenRef = useRef();
  const [targetScroll, setTargetScroll] = useState(0);

  const handleLoadImage = () => {
    if (!screenRef.current || !imageRef.current) {
      return;
    }

    if (!screenRef.current.offsetWidth || !imageRef.current.naturalWidth) {
      setTimeout(() => handleLoadImage(), 200);
      return;
    }

    setTargetScroll(
      imageRef.current.offsetHeight - screenRef.current.offsetHeight,
    );
  };

  useEffect(() => {
    handleLoadImage();
  });

  return (
    <Outer>
      <Wrapper>
        <Screen ref={screenRef}>
          <ScreenContent
            ref={imageRef}
            src={source}
            style={{ transform: `translateY(${-1 * targetScroll}px)` }}
          />
        </Screen>

        <img src={laptop} alt="" />
      </Wrapper>

      <Smartphone source={mobileSource} />
    </Outer>
  );
};

export default Laptop;
