import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Container, Buttons, media } from 'ui';
import { Button } from './';
import { get } from 'lodash';
import hero from 'images/hero.svg';
import HeroShape from 'components/HeroShape';
import heroIcons from 'images/hero-icons.svg';
import { HeroCarousel } from 'components';

export const MAX_HERO_BOTTOM_DIMENSION = 300;

const HeroWrapper = styled.div`
  position: relative;
  background-image: url(${hero});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${media.desktop`
    background-image: url(${heroIcons}), url(${hero});
    background-size: contain, cover;
  `}
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 25vw;
  // margin-bottom: 25vw;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  ${media.desktop`
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
    padding-bottom: ${MAX_HERO_BOTTOM_DIMENSION / 2}px;
    // margin-bottom: ${MAX_HERO_BOTTOM_DIMENSION / 2}px;
  `};
`;

const HeroTitle = styled.h2`
  font-size: 2rem;
  font-size: min(calc(1.5rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 2rem);
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: bold;
  color: #fff;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const HeroSubtitle = styled.div`
  font-size: 1.25rem;
  font-size: min(calc(1rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 1.25rem);
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  line-height: 32px;
`;

const PostCardWrapper = styled.div`
  width: 100%;

  ${media.desktop`
    padding: 0 60px;
  `}
`;

const PostCard = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  display: grid;
  padding: 7px;
  box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.15);
  background: repeating-linear-gradient(
    135deg,
    #3039f5,
    #3039f5 10px,
    #fff 10px,
    #fff 20px,
    #eb332f 20px,
    #eb332f 30px,
    #fff 30px,
    #fff 40px
  );
  border-radius: 10px;
  max-height: 510px;
  max-width: 525px;
`;

const Hero = ({ data, locale }) => (
  <HeroWrapper>
    <HeroShape />

    <HeroContent>
      <Container size="narrow">
        <HeroTitle>{get(data, 'datoCmsHomepage.onewayTitle')}</HeroTitle>
        <HeroSubtitle>
          <ReactMarkdown>
            {get(data, 'datoCmsHomepage.onewaySubtitle')}
          </ReactMarkdown>
        </HeroSubtitle>
        <HeroSubtitle>
          <ReactMarkdown>
            {get(data, 'datoCmsHomepage.onewaySubtitle2')}
          </ReactMarkdown>
        </HeroSubtitle>
        <HeroSubtitle>
          <ReactMarkdown>
            {get(data, 'datoCmsHomepage.onewaySubtitle3')}
          </ReactMarkdown>
        </HeroSubtitle>
        <Buttons>
          <Button
            variant="secondary"
            to={get(data, 'datoCmsHomepage.onewayCtaLink')}
          >
            {get(data, 'datoCmsHomepage.onewayCtaTitle')}
          </Button>
        </Buttons>
      </Container>

      {/* <Laptop
        source={get(data, 'datoCmsHomepage.mainPicture.url')}
        mobileSource={get(data, 'datoCmsHomepage.mobilePicture.url')}
      /> */}
      <PostCardWrapper>
        <PostCard>
          <HeroCarousel data={get(data, 'allDatoCmsOneway.edges')} />
        </PostCard>
      </PostCardWrapper>
    </HeroContent>
  </HeroWrapper>
);

export default Hero;
