import React, { useEffect, useState } from 'react';
import { media, viewportSizes } from 'ui';
import leftShape from 'images/left-shape.svg';
import rightShape from 'images/right-shape.svg';
import centerShape from 'images/center-shape.svg';
import styled from 'styled-components';
import { uniqueId } from 'lodash';

const Image = styled.img`
  position: absolute;
  z-index: 1;
  max-height: 100% !important;
  max-width: 80vw;
  left: 0;
  transform-origin: left center;

  ${media.tablet`
    width: calc(100% + ${({ theme }) => theme.spacing(4)});
    top: 50%;
  `}

  ~ * {
    position: relative;
    z-index: 2;
  }

  ${({ layout }) =>
    layout === 'rightWithShape' &&
    `
      left: auto;
      right: 0;
      transform-origin: right center;
    `}

  ${({ layout }) =>
    layout === 'centerWithShape' &&
    `
      transform-origin: center center;
      width: calc(100% + 64px) !important;
    `}
`;

const getShape = layout => {
  switch (layout) {
    case 'leftWithShape':
      return leftShape;
    case 'rightWithShape':
      return rightShape;
    case 'centerWithShape':
      return centerShape;
    default:
      return null;
  }
};

const getShapeDirection = layout => {
  switch (layout) {
    case 'leftWithShape':
      return -1;
    case 'rightWithShape':
      return 1;
    case 'centerWithShape':
    default:
      return 0;
  }
};

const SectionShape = ({ layout }) => {
  const src = getShape(layout);
  const [hash, setHash] = useState(uniqueId());
  const [width, setWidth] = useState();

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      setWidth(document.documentElement.offsetWidth);
    }

    const interval = setInterval(() => setHash(uniqueId()), 1000);

    return () => clearInterval(interval);
  }, [hash]);

  if (!src || !width) {
    return null;
  }

  const translateDirection = getShapeDirection(layout);
  const scaleKind = layout === 'centerWithShape' ? 'scaleX' : 'scale';
  const scale =
    width > 1200
      ? layout === 'centerWithShape'
        ? width / (1200 - 64)
        : (width - 64) / 2 / ((1200 - 64) / 2)
      : (width + 64) / width;
  const translateX =
    width > 1200
      ? translateDirection * ((width - 1200) / 2 + 16)
      : translateDirection * 16;
  const translateY = width >= viewportSizes.tablet ? '-50%' : 0;

  return (
    <Image
      hash={hash}
      style={{
        transform: `translateY(${translateY}) translateX(${translateX}px) ${scaleKind}(${scale})`,
      }}
      src={getShape(layout)}
      layout={layout}
    />
  );
};

export default SectionShape;
