import React from 'react';
import { SEO, Button } from 'components';
import { Container, SectionTitle, media, Buttons } from 'ui';
import styled from 'styled-components';
import { ContactForm } from 'components';
import { get } from 'lodash';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;

  ${media.desktop`
  grid-template-columns: 35% auto;
  grid-column-gap: ${({ theme }) => theme.spacing(4)}
`};
`;

const Icon = styled.img`
  max-width: 60px;
`;

const Title = styled.h4`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 1.375rem;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Description = styled.p`
  line-height: 1.5;
  color: ${({ theme }) => theme.textLight};
`;

const ContactByPhone = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const Demo = ({ data, locale }) => (
  <>
    <SEO
      title={get(data, 'datoCmsDemo.seo.title')}
      description={get(data, 'datoCmsDemo.seo.description')}
      image={get(data, 'datoCmsDemo.seo.image.url')}
      twitterCard={get(data, 'datoCmsDemo.seo.twitterCard')}
      datoCmsSite={data.datoCmsSite}
    />

    <Container variant="main">
      <SectionTitle>{data.datoCmsDemo.title}</SectionTitle>
      <Grid>
        <ContactByPhone>
          <Icon src={data.datoCmsDemo.contactByPhoneIcon.url} alt="" />
          <Title>{data.datoCmsDemo.contactByPhoneTitle}</Title>
          <Description>
            {data.datoCmsDemo.contactByPhoneDescription}
          </Description>
          <Buttons alignment="left">
            <Button as="a" href={`tel:${data.datoCmsDemo.phoneNumber}`}>
              {data.datoCmsDemo.phoneNumber}
            </Button>
          </Buttons>
        </ContactByPhone>
        <Card>
          <iframe
            class="airtable-embed"
            src="https://airtable.com/embed/shraYjP1bU8e90sCw"
            frameborder="0"
            width="100%"
            style={{ height: '73.5em' }}
          ></iframe>
        </Card>
        {/* <Card>
          <Icon src={data.datoCmsDemo.contactIcon.url} alt="" />
          <Title>{data.datoCmsDemo.contactTitle}</Title>
          <ContactForm data={data} locale={locale}></ContactForm>
        </Card> */}
      </Grid>
    </Container>
  </>
);

export default Demo;
