import styled, { css } from 'styled-components';

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;

export const theme = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',

  primary: '#ff5b4a',
  primaryLight: 'ffaca4',
  primaryGradient: 'linear-gradient(180deg, #ff7250 0%, #ff3a2c 100%)',

  separator: 'rgba(0,0,0,0.12)',
  separatorDark: 'rgba(0,0,0,0.48)',

  success: '#29cc96',
  error: '#F14C66',

  buttonShadow: '0 8px 16px -8px #FF8B80',
  buttonShadowSecondary: '0 4px 8px -4px #66ccaa',

  secondary: '#66ccaa',
  secondaryLight: '#2ee6a9',
  secondaryGradient:
    'linear-gradient(rgb(46, 230, 169), rgb(41, 204, 150)) rgb(46, 230, 169)',

  boxShadow: '0 4px 16px 0 rgba(0,0,0,0.12)',

  background: '#FAF8F5',
  backgroundGradient: 'linear-gradient(to top, #faf9f7, #ffffff 100px)',

  spacing,

  fonts: {
    title:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body:
      'Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },
};

export const viewportSizes = {
  desktop: 1024,
  tablet: 800,
  tabletMini: 500,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};

export const ButtonReset = styled.button`
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  outline: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: ${spacing()};
  margin-left: auto;
  margin-right: auto;

  ${({ variant }) =>
    variant === 'barePadding' &&
    `
      padding: 0
    `}

  ${({ variant }) =>
    variant === 'main' &&
    `
    padding-top: ${spacing(4)};
    padding-bottom: ${spacing(4)};
  `}

  ${({ paddingVariant }) =>
    paddingVariant === 'bareVertical' &&
    `
      padding-top: 0;
      padding-bottom: 0;
    `}

  ${({ size }) =>
    size === 'narrow' &&
    `
      max-width: 50em;
    `}
`;

export const MediaImage = styled.div`
  display: flex;
`;

export const MediaBody = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing()};

  ${props =>
    props.variant === 'compact' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(0.5)};
    `};
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;

  ${props =>
    (props.variant === 'center' || props.verticalAlign === 'center') &&
    css`
      align-items: center;
    `};

  ${({ size }) =>
    size === 'big' &&
    css`
      ${MediaBody} {
        ${media.tablet`
          padding-left: ${({ theme }) => theme.spacing(2)};
        `}
      }
    `};

  ${props =>
    props.variant === 'inverted' &&
    css`
      ${MediaBody} {
        padding-left: 0;
      }
    `}
`;

export const Buttons = styled.div`
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-top: ${({ theme }) => theme.spacing(-1)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-left: ${({ theme }) => theme.spacing()};
    margin-top: ${({ theme }) => theme.spacing()};
  }

  ${({ alignment }) =>
    alignment === 'right' &&
    `
      justify-content: flex-end;
    `};

  ${({ alignment }) =>
    alignment === 'left' &&
    `
      justify-content: flex-start;
    `}

  ${({ hasTopMargin, theme }) =>
    hasTopMargin &&
    `
      margin-top: ${theme.spacing()};
    `};

  ${props =>
    props.spacingSize === 'small' &&
    css`
      margin-left: ${({ theme }) => theme.spacing(-0.5)};
      margin-top: ${({ theme }) => theme.spacing(-0.5)};

      > * {
        margin-left: ${({ theme }) => theme.spacing(0.5)};
        margin-top: ${({ theme }) => theme.spacing(0.5)};
      }
    `}

  ${props =>
    props.spacingSize === 'big' &&
    css`
      margin-left: ${({ theme }) => theme.spacing(-2)};
      margin-top: ${({ theme }) => theme.spacing(-2)};

      > * {
        margin-left: ${({ theme }) => theme.spacing(2)};
        margin-top: ${({ theme }) => theme.spacing(2)};
      }
    `}
`;

export const Section = styled.div`
  background: ${({ theme }) => theme.backgroundGradient};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(6)};
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  `}

  position: relative;

  ${({ variant }) =>
    variant === 'transparent' &&
    css`
      background: transparent;
    `}
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-areas:
    'content content'
    'image image';

  ${media.tablet`
    grid-template-areas: 'content image';
  
    ${({ layout }) =>
      layout === 'left' &&
      `
        grid-template-areas: 'image content';
      `}

    ${({ layout }) =>
      layout === 'leftWithShape' &&
      `
        grid-template-areas: 'image content';
      `}

    ${({ layout }) =>
      (layout === 'center' || layout === 'centerWithShape') &&
      `
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center
      `}
  `}
`;

export const SectionContent = styled.div`
  grid-area: content;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SectionShape = styled.img`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(4)};
  width: 100%;
  height: 500px;

  ${media.tablet`height: 300px;`};
`;

export const SectionImage = styled.div`
  grid-area: image;
  background-size: cover;
  position: relative;
  width: 100%;
  align-self: center;

  img {
    max-width: 100%;
    max-height: min(600px, 90vh);
    width: auto;
    display: block;

    ${({ layout }) =>
      layout === 'rightWithShape' &&
      `
        margin-left: auto;
      `}

    ${({ layout }) =>
      layout === 'centerWithShape' &&
      `
        margin-left: auto;
        margin-right: auto;
      `}
  }

  ${({ layout, theme }) =>
    layout === 'centerWithShape' &&
    `
      margin-top: ${theme.spacing(2)};

      ${media.tablet`
        margin-top: ${theme.spacing(4)};
      `}
  `}
`;

export const SectionSubtitle = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-size: 1.25rem;
  font-size: min(calc(1rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 1.25rem);

  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${({ alignment }) =>
    alignment === 'center' &&
    `
      text-align: center
    `}
`;

export const SectionTitle = styled.h3`
  font-size: 2rem;
  font-size: min(calc(1.5rem + (2 - 1) * ((100vw - 20em) / (50 - 20))), 2rem);
  line-height: 1.2;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-family: ${({ theme }) => theme.fonts.title};

  ${media.tablet`
    font-size: 2.25rem;
  `}

  ${({ alignment }) =>
    alignment === 'center' &&
    css`
      text-align: center;
    `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
      color : ${theme.primary};
    `}
  
  + ${SectionSubtitle} {
    margin-top: ${({ theme }) => theme.spacing(-1)};
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const FeaturesList = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing(6)};
  grid-row-gap: ${({ theme }) => theme.spacing(4)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const BenefitsList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const AwardsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${({ theme }) => theme.spacing(4)};
  justify-items: center;
  align-items: center;

  ${media.desktop`
  grid-template-columns: repeat(4, 1fr);
`}
`;

export const Award = styled.img`
  width: 100%;
  max-width: 160px;
`;
export const TestimonialsSection = styled(Section)`
  position: relative;

  > ${Container} {
    position: relative;
    z-index: 1;
  }
`;

export const TestimonialsList = styled.div`
  display: grid;
  padding-top: ${({ theme }) => theme.spacing(4)};
  grid-gap: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
    grid-gap: ${({ theme }) => theme.spacing(4)};
    grid-template-columns: 1fr 1fr;
  `};
`;

export const CallToActionWrapper = styled.div`
  background-color: ${({ theme }) => theme.success};
  color: #fff;
  border-radius: 16px;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing(2)};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) =>
    theme.spacing(4)};
    grid-template-columns: 1fr auto;
  `}
`;

export const CallToActionTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
  font-size: 26px;
`;

export const ButtonText = styled(ButtonReset)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
`;
