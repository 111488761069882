import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import qs from 'query-string';
import { Button } from 'components';
import { TextField } from 'components/Forms';
import { ErrorMessageWrapper } from 'ui/forms';
import { GATSBY_INTEGRATELY_URL_CONTACT } from '../constants';
import { Buttons, media, ButtonText } from 'ui';
import { ERRORS, getLabelFromLocale, getPlaceholderFromLocale } from 'utils';
import { MdChevronLeft } from 'react-icons/md';
import Lottie from 'react-lottie';
import checkAnimation from 'images/lottieCheck.json';

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(1.125)};

  ${media.tablet`
  grid-template-columns: repeat(2, 1fr);
  `}
`;

const Description = styled.div`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.textLight};

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

const ContactForm = ({ data, locale }) => {
  const [step, setStep] = useState(1);

  const handleValidContactForm = async (
    values,
    { setSubmitting, setStatus },
  ) => {
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(values),
      url: GATSBY_INTEGRATELY_URL_CONTACT,
    };

    try {
      await axios(options);
      setSubmitting(false);
      setStep(2);
    } catch (err) {
      console.dir(err);
      setSubmitting(false);
      setStatus(ERRORS.ERROR);
    }
  };

  return (
    <>
      {step === 1 && (
        <Formik
          onSubmit={handleValidContactForm}
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            company: '',
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string().required(ERRORS.REQUIRED),
            lastname: Yup.string().required(ERRORS.REQUIRED),
            email: Yup.string().required(ERRORS.REQUIRED),
            phoneNumber: Yup.string().required(ERRORS.REQUIRED),
            company: Yup.string().required(ERRORS.REQUIRED),
          })}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <FormGrid>
                <TextField
                  name="firstname"
                  label={getLabelFromLocale('firstname', locale)}
                  placeholder={getPlaceholderFromLocale('firstname', locale)}
                />
                <TextField
                  name="lastname"
                  label={getLabelFromLocale('lastname', locale)}
                  placeholder={getPlaceholderFromLocale('lastname', locale)}
                />
                <TextField
                  name="email"
                  label={getLabelFromLocale('email', locale)}
                  placeholder={getPlaceholderFromLocale('email', locale)}
                />
                <TextField
                  name="phoneNumber"
                  label={getLabelFromLocale('phoneNumber', locale)}
                  placeholder={getPlaceholderFromLocale('phoneNumber', locale)}
                />
                <TextField
                  name="company"
                  label={getLabelFromLocale('company', locale)}
                  placeholder={getPlaceholderFromLocale('company', locale)}
                />
              </FormGrid>
              {status && <ErrorMessageWrapper>{status}</ErrorMessageWrapper>}
              <Buttons alignment="left" style={{ marginTop: 32 }}>
                <Button isLoading={isSubmitting}>
                  {data.datoCmsDemo.contactCta}
                </Button>
              </Buttons>
            </Form>
          )}
        </Formik>
      )}

      {step === 2 && (
        <>
          <Lottie
            options={{
              animationData: checkAnimation,
              loop: false,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={280}
            width={280}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: data?.datoCmsDemo?.contentSuccessfulContactFormNode?.childMarkdownRemark?.html.replace(
                /<a/g,
                '<a target="_blank" rel="noopener noreferer nofollow"',
              ),
            }}
          />
          <ButtonText onClick={() => setStep(1)}>
            <MdChevronLeft />
            {data?.datoCmsDemo?.backToContactForm}
          </ButtonText>
        </>
      )}
    </>
  );
};

export default ContactForm;
