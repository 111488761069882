import React from 'react';
import styled from 'styled-components';
import iphone from 'images/iphone.png';
import { media } from 'ui';

const Wrapper = styled.div`
  position: absolute;
  right: 5%;
  width: 15%;
  bottom: 2.5%;
  display: none;

  ${media.tablet`
    display: block;
  `}

  img {
    display: block;
    width: 100%;
  }
`;

const Screen = styled.div`
  position: absolute;
  left: 6.648199446%;
  top: 2.8%;
  right: 6.371191136%;
  height: 94.305555556%;
  overflow: hidden;
  border-radius: 4px;
  background-color: #000;

  + img {
    position: relative;
    z-index: 2;
  }
`;

const ScreenContent = styled.img`
  object-fit: cover;
`;

const Laptop = ({ source }) => (
  <Wrapper>
    <Screen>
      <ScreenContent src={source} />
    </Screen>

    <img src={iphone} alt="" />
  </Wrapper>
);

export default Laptop;
