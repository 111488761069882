import React from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../styles.css';

const Wrapper = styled.div`
  // overflow: hidden;
  display: flex;
  padding: 20px;
  background: #fff;

  li {
    background: none;
    padding: 0;
  }
`;

const Logo = styled.img`
  height: auto;
  max-height: 500px;
`;

const HeroCarousel = ({ data }) => {
  return (
    <Wrapper>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        interval={5000}
      >
        {data
          .sort((a, b) => a.node.order - b.node.order)
          .map(({ node }) => (
            <div>
              <Logo src={node.logo.url} key={node.id} alt={node.name} />
            </div>
          ))}
      </Carousel>
    </Wrapper>
  );
};

export default HeroCarousel;
